
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import TheStepperBookingCargo from "@/app/ui/views/cargo/booking-cargo/components/the-stepper-booking-cargo.vue";
import { defineAsyncComponent } from "vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { ReadyToCargo, ReadyToCargoListEntities, RequestReadyToCargoList } from "@/domain/entities/ReadyToCargo";
import { checkRolePermission, firstCapitalize } from "@/app/infrastructures/misc/Utils";
import { READY_TO_CARGO } from "@/app/infrastructures/misc/RolePermission";
import { CargoInputData } from "@/domain/entities/Cargo";
import { OptionsClass } from "@/domain/entities/MainApp";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { getTokenNGen } from "@/app/infrastructures/misc/Cookies";
import { flags } from "@/feature-flags";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import debounce from "lodash/debounce";
import { GTMBookingCargoEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/booking-cargo-event";
import { FlagsGamificationRTC } from "@/feature-flags/flag-gamification-rtc";
const FirstStep = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/cargo/booking-cargo/first-step/index.vue")
});
const SecondStep = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/cargo/booking-cargo/second-step/index.vue")
});
const ThirdStep = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/cargo/booking-cargo/third-step/index.vue")
});
const CargoProcessCargo = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/cargo/booking-cargo/third-step/cargo-process-cargo.vue"
    )
});
const ListReadyToCargo = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/cargo/booking-cargo/ready-to-cargo-flow/index.vue")
});

@Options({
  components: {
    CargoProcessCargo,
    TheStepperBookingCargo,
    FirstStep,
    SecondStep,
    ThirdStep,
    ListReadyToCargo
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      if (this.sttResultFinal.length) {
        this.onReset();
      }
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class BookingCargo extends Vue {
  created() {
    this.checkFlowBooking();
    CargoController.resetForm();
  }

  fetchDestinationCity = debounce((search: string) => {
    LocationController.getCityList({
      search: search,
      status: "",
      page: 1,
      limit: 10
    });
  }, 250);

  
  get isLoadingCity() {
    return LocationController.isLoadingDestinationCity;
  }

  get optionDestinationCity() {
    return CargoController.optionDestinationCity;
  }

  get destinationCityRTC() {
    return CargoController.destinationCityRTC;
  }

  chooseDestination(param: any) {
    CargoController.setDestinationCityRTC(param);
    this.onChangePagination(1);
    GTMBookingCargoEvent("filter_card_RTC", {
      "3LC_destination": this.destinationCityRTC?.code,
      timestamp: new Date().getTime()
    });
  }

  onChangePagination(newPage: number) {
    this.readyToCargoList.pagination.page = newPage;
    this.fetchReadyToCargoList();
  }

  get titleButtonTopRight() {
    return this.isEditReadyToCargo ? "Lanjutkan" : "Pilih Kargo";
  }

  get showButtonDimension() {
    return flags.feature_hide_dimension_koli.isEnabled();
  }

  get isErrorDiffPiecesActualThanDimensionPieces() {
    if (this.showButtonDimension) {
      return false;
    }
    return CargoController.isErrorDiffPiecesActualThanDimensionPieces;
  }

  get sttResultFinal() {
    return CargoController.sttResultFinal;
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  get isShowPlaneScheduleFromLocalStorage() {
    return CargoController.isShowPlaneScheduleFromLocalStorage;
  }

  async checkFlowBooking() {
    switch (this.flowBooking) {
      case "manual":
        if (CargoController.sourceSttFromLocalStorage.length) {
          this.goToManualBooking();
        } else {
          if (this.readyToCargoList.data.length) {
            CargoController.setProgressValue(-1);
          }
        }
        CargoController.setIsEditReadyToCargo(false);
        break;
      case "rtc-process":
        if (this.cargoInputForm.id == 0) {
          await ReadyToCargoController.getDetailReadyToCargo(
            ReadyToCargoController.readyToCargoDetail.id,
            "rtc-process"
          );
        }
        CargoController.mappingReadyToCargo();
        if (this.form.cargoTypeStatus === "auto") {
          this.form.airportOrigin.airportCode =
            ReadyToCargoController.readyToCargoDetail.originCode;
          this.form.airportDestination.airportCode =
            ReadyToCargoController.readyToCargoDetail.destinationCode;
        }
        if(CargoController.progressValue === 1) {
          if (this.readyToCargoDetailData.cargoType === "plane" && !this.readyToCargoDetailData.cutOffTime) {
          CargoController.setProgressValue(-1);
          this.onReset();
        }
        }
        break;
      case "rtc-edit":
        if (this.cargoInputForm.id == 0) {
          await ReadyToCargoController.getDetailReadyToCargo(
            ReadyToCargoController.readyToCargoDetail.id,
            "rtc-edit"
          );
        }
        CargoController.setIsEditReadyToCargo(true);
        if (CargoController.progressValue == 2) {
          CargoController.setProgressValue(2);
          return;
        }

        if(CargoController.progressValue === 1 || CargoController.progressValue === -1) {
          CargoController.setIsChangeCargoType(false);
          if (this.readyToCargoDetailData.cargoType === "plane" && !this.readyToCargoDetailData.cutOffTime) {
          CargoController.setProgressValue(-1);
          this.onReset();
          return;
        }
        }
        CargoController.setProgressValue(0);
        break;
      case "rebooking":
        if (CargoController.progressValue == 2) { // if rebooking stil on third step, after reload shall stay on third stepp
          CargoController.setProgressValue(2);
          return;
        }
        CargoController.setProgressValue(0);
        break;
      default:
        if (checkRolePermission(READY_TO_CARGO.LIST)) {
          CargoController.setProgressValue(-1);
        } else {
          this.goToManualBooking();
        }
        break;
    }
  }

  goToManualBooking() {
    this.setFlowBooking("manual");
    if (this.progress != 2) {
      if (!CargoController.sttResultFinal.length && FlagsGamificationRTC.feature_is_gamification.isEnabled()) {
        CargoController.setIsModalGamification(true);
        CargoController.setIsModalGamificationDone(false);
      } else {
        CargoController.setProgressValue(0);
      } 
    }
    if (checkRolePermission(READY_TO_CARGO.LIST)) {
      ReadyToCargoController.setEventName("booking_newcargo");
    } else {
      ReadyToCargoController.setEventName("booking_cargo");
    }
  }

  async goToThirdStep() {
    if (this.flowBooking === "rtc-process" && this.isShowPlaneScheduleFromLocalStorage) {
      this.onReset();
      CargoController.setShowPlaneSchedule(false);
      CargoController.setProgressValue(-1);
    }
    if (this.isEditReadyToCargo || this.flowBooking === "rtc-process") {
      let isAuth = false;
      if (!this.isPlaneAuto) {
        CargoController.setProgressValue(2);
        return;
      }
      if (!getTokenNGen() && this.form.cargoType === "plane") {
        isAuth = await NGenController.onLoginNGen();
      } else {
        isAuth = true;
      }
      if (isAuth) {
        if (!this.form.flightsData.length && this.form.cargoType === "plane") {
          await this.initPlaneAuto(this.form);
          this.showSelectAirport = true;
          CargoController.setAirportsPopupModel(
            JSON.parse(JSON.stringify(CargoController.form.airports))
          );
          this.form.cargoType = "plane";
          this.form.cargoTypeStatus = "auto";
          CargoController.setShowSelectAirportToLocalStorage(true);
        } else {
          CargoController.setProgressValue(2);
        }
      }
    } else {
      CargoController.setShowModalCargoType(true);
    }
  }

  get isPlaneAuto() {
    return this.cargoType.type === "plane" && this.cargoType.status === "auto";
  }

  get disabledScheduleOption() {
    return !this.form.destinationCityCode || !parseFloat(
        `${this.form.actualTotalCargoGrossWeight}`.replace(",", ".")
      ) ||
      !parseFloat(
        `${this.form.actualTotalCargoVolumeWeight}`.replace(",", ".")
      ) 
  }

  get cargoType() {
    return CargoController.cargoType;
  }
  get showSelectAirport() {
    return CargoController.showSelectAirport;
  }

  set showSelectAirport(bool: boolean) {
    CargoController.setShowSelectAirport(bool);
  }

  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }

  async initPlaneAuto(data: any): Promise<void> {
    await this.fetchAirportOrigin(this.originCity.code || "");
    await this.fetchAirportDestination(data.destinationCityCode || "");
  }

  fetchAirportOrigin(search: string, hideDefault = true) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListOrigin({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10,
      isLocation: false,
      hideDefault: hideDefault
    });
  }
  fetchAirportDestination(search: string) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListDestination({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10
    });
  }

  setFlowBooking(value: string) {
    ReadyToCargoController.setFlowBooking(value);
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    this.handleBackBookingCargo(
      this.progress,
      this.isPlaneAutoFlow,
      this.isCargoPlaneManual,
      this.isFillForm,
      this.isShowPlaneSchedule,
      this.flowBooking
    );
  }

  handleBackBookingCargo(
    progress: number,
    isPlaneAutoFlow: boolean,
    isCargoPlaneManual: boolean,
    isFillForm: boolean,
    isShowPlaneSchedule: boolean,
    flowBooking: string
  ) {
    if (progress === 2 || progress === 0) {
      if (flowBooking !== 'rebooking' && !this.isFromRebookingRTC) {
      CargoController.setOnChangeVehicleNumber("");
      CargoController.setUpdateFromBookingCargoNumber("");
      CargoController.setOnChangeNog("");
      CargoController.setIsChangeCargoType(false);
      CargoController.setOnSelectCargoProduct("Express");
      }
      if (flowBooking == "rebooking" && !isShowPlaneSchedule && progress === 0) {
        const path = "/cargo";
        this.$router.push(path);
      } else if (isPlaneAutoFlow && isShowPlaneSchedule) {
        //auto
        CargoController.setPlaneAutoFlow(false);
        CargoController.setShowPlaneSchedule(false);
        return;
      } else if (isPlaneAutoFlow || isCargoPlaneManual || isFillForm) {
        //manual/auto
        if (flowBooking === "rtc-process") {
          //from rtc-process step 2
          this.onReset();
          CargoController.setProgressValue(-1);
          CargoController.setFillForm(false);
          return;
        }
        //back on rtc-edit first step all cargo type and cargo status
        if (flowBooking === "rtc-edit" && progress === 0) {
          //from rtc-process step 2
          this.onReset();
          CargoController.setProgressValue(-1);
          CargoController.setFillForm(false);
          return;
        }
        if ((flowBooking === "manual" || flowBooking === "rebooking") && progress === 2) {
          CargoController.setProgressValue(0);
          CargoController.setFillForm(false);
          CargoController.setCargoType({});
          CargoController.setDefaultFlightData();
          CargoController.setEditFlightData([]);
          if (isCargoPlaneManual) CargoController.resetForm();
          return;
        }
        this.fetchReadyToCargoList();
        if (flowBooking !== "rtc-edit") {
          this.form.cargoType = "";
          this.form.cargoTypeStatus = "";
          CargoController.setDefaultFlightData();
        }
        const isPlaneScheduleExist = !!this.readyToCargoDetailData?.flightsData[0]?.departureHour;
        if (flowBooking != "rtc-process" && !isPlaneScheduleExist) {
          CargoController.resetFlightData();
          CargoController.setResetDefaultAirportOrigin();
        }
        return;
      } else {
        //from step 0 to step -1 (list ready to cargo)
        CargoController.setProgressValue(-1);
        CargoController.setIsEditReadyToCargo(false);
        this.onReset();
        return;
      }
    } else {
      if (this.flowBooking.includes("rtc") || this.flowBooking === 'manual' || this.flowBooking === 'rebooking') {
        if (this.flowBooking.includes("rtc")) {
        CargoController.setCargoType({type: this.readyToCargoDetailData.cargoType, status :this.readyToCargoDetailData.cargoType == "plane" ? "auto": "manual" })
        this.setFlightDataFromRTC();
        }
        CargoController.setIsChangeCargoType(false);
        CargoController.setFillForm(false);
        CargoController.setPlaneAutoFlow(false);
        if (this.readyToCargoDetailData.cargoType === "plane" && this.readyToCargoDetailData.isMoreThanCutOffTime) {
          CargoController.setProgressValue(-1);
          this.onReset();
        } else {
          CargoController.setProgressValue(0);
        }
        return;
      }
      this.onReset();
      const path = "/cargo";
      this.$router.push(path);
    }
  }

  async fetchReadyToCargoList() {
    MainAppController.showLoading();
    try {
      CargoController.setReadyToCargoList(await ReadyToCargoController.getReadyToCargoList(
        new RequestReadyToCargoList({
          page: this.readyToCargoList.pagination.page,
          limit: this.readyToCargoList.pagination.limit,
          destinationCode: this.destinationCityRTC?.code,
          transportType: CargoController.activeTabs,
        })
      ));
      if (!this.readyToCargoList.data.length && this.progress !== -1) {
        this.$router.push("/cargo");
      } else {
        CargoController.setProgressValue(-1);
      }
    } catch (error) {
      this.$router.push("/cargo");
    } finally {
      MainAppController.closeLoading();
    }
  }

  get readyToCargoList () {
    return CargoController.readyToCargoList;
  }

  setFlightDataFromRTC() {
    const backupFlight = JSON.parse(localStorage.getItem("backup-flight-rtc") || "[]");
    this.form.flightsData = backupFlight;
    CargoController.setEditFlightData(backupFlight);
    storeDatatoLocalStorage('backup-flight-rtc', []);
  }

  get detailFlightsData() {
    return ReadyToCargoController.detailFlightsData;
  }

  get readyToCargoDetailData() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get isPlaneAutoFlow() {
    return CargoController.isPlaneAutoFlow;
  }

  get isFillForm() {
    return CargoController.isFillForm;
  }

  get isCargoPlaneManual() {
    return this.form.cargoType === "plane" && this.form.cargoTypeStatus !== "auto";
  }

  onLeavePage(path?: string) {
    this.answerLeavingPage = true;
    const nextPath = path || this.nextPath;
    this.$router.push(nextPath);
    this.onReset();
    CargoController.setPlaneAutoFlow(false);
    CargoController.setFillForm(false);
    if (this.readyToCargoList.data.length)
    CargoController.setProgressValue(-1);
  }
  cancelLeavePage() {
    if (!CargoController.isModalGamificationDone && this.flowBooking === "manual") {
      CargoController.setIsModalGamification(true);
    }
  }

  onReset() {
    this.setFlowBooking("");
    storeDatatoLocalStorage("cargo", []);
    storeDatatoLocalStorage("cargo-bag", []);
    storeDatatoLocalStorage("event-name", "");
    storeDatatoLocalStorage("rtc-flights-data", []);
    storeDatatoLocalStorage("calculate-flight-date", []);
    storeDatatoLocalStorage("show-select-airport", false);
    storeDatatoLocalStorage("cargo-type", {});
    storeDatatoLocalStorage("fill-form", false);
    storeDatatoLocalStorage("error-awb", "");
    storeDatatoLocalStorage("isEditReadyToCargo", false);
    storeDatatoLocalStorage("error-awb", "");
    storeDatatoLocalStorage("setShowPlaneSchedule", false);
    storeDatatoLocalStorage("is-modal-gamification", false);
    storeDatatoLocalStorage("is-from-rebooking-rtc", false);
    storeDatatoLocalStorage("is-change-threshold-time", false);
    storeDatatoLocalStorage("is-delete-stt", false);
    storeDatatoLocalStorage("is-change-total-actual-volume", false)
    CargoController.setSTTResultFinal([]);
    CargoController.setBagResult([]);
    CargoController.setSourceFromLocalStorage([]);
    ReadyToCargoController.setCalculateFlightDate([]);
    ReadyToCargoController.setDetailFlightData([]);
    CargoController.setNotificationWeight(false);
    CargoController.setResetDestinationCity();
    CargoController.resetTotalActualGrossWeight();
    CargoController.resetCargoInputForm(new CargoInputData());
    CargoController.setShowModalCargoType(false);
    NGenController.setErrorAwb("");
    CargoController.setShowSelectAirportToLocalStorage(false);
    CargoController.resetIsEditReadyToCargo();
    ReadyToCargoController.setReadyToCargoDetail(new ReadyToCargo());
    CargoController.resetForm();
    CargoController.setHaveBeenSubmit(false);
    CargoController.setFormDetailPieces({
      totalPieces: 0,
      dimensionPieces: [
        {
          pieces: 0,
          length: 30,
          width: 20,
          height: 10,
          grossWeight: 1,
        },
      ],
    });
    CargoController.setDestinationCityRTC("");
    CargoController.setIsChangeVolumeActual(false);
  }

  get progress(): number {
    return CargoController.progressValue;
  }

  get form() {
    return CargoController.form;
  }
  get isDisableFindCargo() {
    return (
      this.form.actualTotalCargoGrossWeight &&
      +this.form.actualTotalCargoGrossWeight.replace(",", ".") &&
      this.form.actualTotalCargoVolumeWeight &&
      +this.form.actualTotalCargoVolumeWeight.replace(",", ".") &&
      this.form.actualDimension.length &&
      this.form.actualDimension.width &&
      this.form.actualDimension.height &&
      this.form.cargoCommodityCode &&
      this.form.actualTotalSTTPieces &&
      !this.form.actualTotalSTTPieces.toString().includes(",") &&
      this.form.actualTotalSTTPieces > 0
    );
  }

  get isEditReadyToCargo() {
    return CargoController.isEditReadyToCargo;
  }

  get isRTC() {
    return ReadyToCargoController.flowBooking.includes("rtc");
  }

  get isRebookingRTC() {
    return this.flowBooking === "rebooking" && this.$route.query.rcId && this.isFromRebookingRTC;
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }

  get dataDetailRetryCargo() {
    return CargoController.cargoDetailData;
  }

  get tokenNgen() {
    return getTokenNGen();
  }

  async showModalPlaneAuto(isAuthNgen: any) {
    CargoController.resetFlightData();
    CargoController.setResetDefaultAirportOrigin();
    const isAuth = isAuthNgen ? true : await NGenController.onLoginNGen();
    if (isAuth) {
       await this.initPlaneAuto({destinationCityCode: this.form.destinationCityCode});
        CargoController.setAirportsPopupModel(
          JSON.parse(JSON.stringify(CargoController.form.airports))
        );
        CargoController.setShowSelectAirportToLocalStorage(true);
        CargoController.setAirportsPopupModel(
          JSON.parse(JSON.stringify(CargoController.form.airports))
        );
        this.showSelectAirport = true;
    }
  }


  get isShowPlaneSchedule() {
    return CargoController.isShowPlaneSchedule;
  }
}
